<template>
  <v-container
    class="px-0 py-0"
    fluid
  >
    <v-card
      v-if="items"
      class="mt-3 mx-3 bt-1 br-1 bl-1 bc-outlined-gray"
      tile
    >
      <v-card-text class="fs-15 py-0">
        <v-row dense>
          <v-col class="d-flex align-center">
            <p class="mb-0 py-4">
              {{ $t('Displaying') }}
              <strong>{{ items?.length }}</strong> {{ $t('out of') }}
              <strong>{{ totalItemCount }}</strong> {{ $t('total results') }}.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-data-table-server
      v-if="items"
      @click:row="handleRowClick"
      @update:page="handlePageChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No statements found"
      fixed-header
      hover
      return-object
    >
      <template #item.id="{ item }">
        <span>
          {{ item.id?.split('-')[0] }}
        </span>
      </template>

      <template #item.amount="{ item }">
        <span>
          {{ currency(item.amount) }}
        </span>
      </template>

      <template #item.startDate="{ item }">
        <span>
          {{ getStartDate(item.due_date) }}
        </span>
      </template>

      <template #item.endDate="{ item }">
        <span>
          {{ getEndDate(item.due_date) }}
        </span>
      </template>

      <template #item.status="{ item }">
        <span>
          {{ capitalize(item.status) }}
        </span>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <BillingStatementDialog
      ref="statementShow"
      :api="props.statementsApi"
      :can-open-payments="canOpenPayments"
    />
  </v-container>
</template>

<script setup>
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import BillingStatementDialog from '@/shared/components/billing/BillingStatementDialog.vue';
import { capitalize, currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  canOpenPayments: Boolean,
  statementsApi: {
    type: Object,
    default: () => ({}),
  },
});

const items = ref(null);
const page = ref(1);
const pageSize = ref(15);
const processing = ref(null);
const statementDates = ref([]);
const statuses = ref([]);
const totalItemCount = ref(null);
const statementShow = ref(null);

const tableHeaders = computed(() => {
  return [
    {
      title: t('ID'),
      value: 'id',
    },
    {
      title: t('Statement Date'),
      value: 'due_date',
    },
    {
      title: t('Start Date'),
      value: 'startDate',
    },
    {
      title: t('End Date'),
      value: 'endDate',
    },
    {
      title: t('Amount'),
      value: 'amount',
      align: 'end',
    },
    {
      title: t('Status'),
      value: 'status',
    },
  ];
});

function formatUTCDate(date) {
  return date.toISOString().split('T')[0];
}

function getEndDate(dueDate) {
  if (!dueDate) return null;
  const dueDateDateTime = new Date(dueDate + 'T00:00:00Z');

  // Set to the first day of the current month
  dueDateDateTime.setUTCDate(1);
  // Subtract one day
  dueDateDateTime.setUTCDate(0);

  const lastDayOfPreviousMonth = new Date(dueDateDateTime);
  return formatUTCDate(lastDayOfPreviousMonth);
}

function getStartDate(dueDate) {
  if (!dueDate) return null;
  const dueDateDateTime = new Date(dueDate + 'T00:00:00Z');

  // Set to the first day of the current month
  dueDateDateTime.setUTCDate(1);
  // Subtract one day
  dueDateDateTime.setUTCDate(0);
  // Set to the first day of the previous month
  dueDateDateTime.setUTCDate(1);

  const firstDayOfPreviousMonth = new Date(dueDateDateTime);
  return formatUTCDate(firstDayOfPreviousMonth);
}

function handlePageChange(newPageValue) {
  page.value = newPageValue;
  void load();
}

function handleRowClick(_event, { item }) {
  openStatement(item.id);
}

async function load() {
  await loadStatements();

  void loadStatementDates();
  void loadStatuses();
}

async function loadStatements() {
  const response = await props.statementsApi.index({
    page: page.value,
    page_size: pageSize.value,
  });

  items.value = response.data;
  totalItemCount.value = parseInt(response.headers['x-total-count']);
}

async function loadStatementDates() {
  statementDates.value = items.value.map((item) => item.date);
}

async function loadStatuses() {
  statuses.value = ['pending', 'approved', 'unpaid', 'paid', 'canceled'];
}

function openStatement(billingStatementId) {
  statementShow.value.open(billingStatementId);
}

onMounted(load);
</script>
