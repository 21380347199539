import LinkedList from '@/shared/services/linked-list';
import { useStore } from 'vuex';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import api from '@/shared/services/all_bright_finder';
import { useI18n } from 'vue-i18n';
import useAssets from '@/shared/composables/useAssets';

export default function useAttendance(scopedApi, subsidyProgramId) {
  const ATTENDANCE_LOG_HOURS_FIELDS = [
    {
      text: 'Hours attended',
      value: 'hours',
      type: 'number',
      required: true,
      max: 24,
      min: 0,
    },
  ];
  const { updateQuery } = useRouterHelper();
  const { t } = useI18n();
  const store = useStore();
  const { assets } = useAssets();

  const attendanceLogStatusDialog = ref(null);
  const attendanceLogQuestionDialog = ref(null);
  const attendanceLogHoursDialog = ref(null);
  const operatingLogStatusDialog = ref(null);
  const attendanceLogs = ref([]);
  const attendanceLogSchema = computed(() =>
    Object.values(store.state.schemas).find((schema) => schema.data_type === 'AttendanceLog'),
  );
  const attendanceLogQuestions = ref([]);
  const attendanceTotals = ref({});
  const errors = ref([]);
  const processing = ref(false);
  const providerId = ref(null);
  const operatingLogs = ref([]);
  const operatingLogSchema = computed(() =>
    Object.values(store.state.schemas).find((schema) => schema.data_type === 'OperatingLog'),
  );
  const queuedAttendanceLogQuestionId = ref(null);
  const queuedAttendanceLogAnswers = ref([]);
  const queuedOperatingLogChanges = ref([]);
  const presentStatusCodes = computed(() =>
    store.state.config.attendance_statuses
      .filter((status) => !status.absent)
      .map((status) => status.code),
  );
  const providers = ref([]);
  const programs = ref([]);
  const queuedAttendanceLogChanges = ref([]);
  const selectedOperatingLog = ref(null);
  const selectedProgram = ref(null);
  const showAttendanceLogChangeQueue = ref(false);
  const showAttendanceLogQuestionQueue = ref(false);
  const showOperatingLogChangeQueue = ref(false);
  const dailyTotals = ref({});

  const selectedMonth = computed(() => {
    if (selectedOperatingLog.value) {
      return [
        assets.months[selectedOperatingLog.value.month],
        selectedOperatingLog.value.year,
      ].join(' ');
    }
    return null;
  });

  function calculateAttendanceTotals() {
    let localTotals = {};
    attendanceLogs.value.forEach((al) => {
      let total = 0;
      for (let i = 1; i <= 31; i++) {
        if (wasPresentOnDay(al, i)) {
          total += 1;
        }
      }
      localTotals[al.id] = total;
    });
    attendanceTotals.value = localTotals;
  }

  function calculateDailyTotals() {
    for (let i = 1; i <= 31; i++) {
      if (dailyTotals.value[i]) {
        dailyTotals.value[i] = 0;
      }
    }

    attendanceLogs.value.forEach((al) => {
      for (let i = 1; i <= 31; i++) {
        dailyTotals.value[i] = dailyTotals.value[i] || 0;
        if (wasPresentOnDay(al, i)) {
          dailyTotals.value[i] += 1;
        }
      }
    });
  }

  async function changeOperatingLog(event) {
    await changeOperatingLogById(event.operatingLogId);
  }

  async function changeOperatingLogById(operatingLogId) {
    selectedOperatingLog.value = operatingLogs.value.find((ol) => ol.id === operatingLogId);
    await updateQuery({ operatingLogId: selectedOperatingLog.value.id });
    loadAttendanceLogs();
  }

  async function changeProgram(evt) {
    selectedProgram.value = programs.value.find((program) => program.id === evt.programId);
    await updateQuery({ programId: selectedProgram.value.id });
    await loadOperatingLogs();
  }

  async function changeProvider(evt) {
    providerId.value = evt.providerId;
    await loadPrograms();
  }

  async function clearAttendanceLog() {
    if (!confirm(t('Are you sure you want to clear the selected attendance records?'))) return;

    await updateAttendanceLogsStatus(null);
  }

  async function clearOperatingLog() {
    if (!confirm(t('Are you sure you want to clear the selected days?'))) return;

    await updateOperatingLog(null);
  }

  function clearAttendanceChangeQueue() {
    queuedAttendanceLogChanges.value = [];
    showAttendanceLogChangeQueue.value = false;
  }

  function clearOperatingLogChangeQueue() {
    queuedOperatingLogChanges.value = [];
    showOperatingLogChangeQueue.value = false;
  }

  function clearAttendanceLogQuestionQueue() {
    queuedAttendanceLogAnswers.value = [];
    queuedAttendanceLogQuestionId.value = null;
    showAttendanceLogQuestionQueue.value = false;
  }

  function handleEditAttendanceLogStatus() {
    if (attendanceLogSchema.value.meta.enable_hourly_attendance) {
      attendanceLogHoursDialog.value.open();
    } else {
      attendanceLogStatusDialog.value.open();
    }
  }

  function loadAttendanceLogs() {
    scopedApi().attendance_log.index(
      { operating_log_id: selectedOperatingLog.value?.id },
      (resp) => {
        attendanceLogs.value = resp.data;
        calculateDailyTotals();
        calculateAttendanceTotals();
      },
    );
  }

  async function loadAttendanceLogQuestions() {
    const resp = await api.public_api.organization.question.index({
      owner_id: attendanceLogSchema.value?.id,
      owner_type: 'Schema',
    });
    attendanceLogQuestions.value = LinkedList.sort(resp.data).filter(
      (question) => question.published,
    );
  }

  async function loadOperatingLogs(operatingLog) {
    const resp = await scopedApi().operating_log.index({
      limit: 12,
      program_id: selectedProgram.value.id,
    });

    operatingLogs.value = resp.data;
    selectedOperatingLog.value = operatingLog || operatingLogs.value?.[0];

    if (selectedOperatingLog.value) {
      await updateQuery({ operatingLogId: selectedOperatingLog.value.id });
      loadAttendanceLogs();
    }
  }

  async function loadPrograms() {
    if (!providerId.value) return;

    attendanceLogs.value = [];
    operatingLogs.value = [];

    const programFilters = {
      subsidy_program_id: subsidyProgramId,
    };

    const { data } = await api.program.index(providerId.value, programFilters);
    programs.value = data;
  }

  async function loadProviders() {
    const providerFilters = {
      subsidy_program_id: subsidyProgramId,
    };
    const { data } = await scopedApi().provider.index(providerFilters);

    providers.value = data;
    await loadPrograms();
  }

  function openAttendanceLogQuestionDialog() {
    const question = attendanceLogQuestions.value.find(
      (question) => question.id === queuedAttendanceLogQuestionId.value,
    );
    attendanceLogQuestionDialog.value.open({ question });
  }

  function queueAttendanceLogChange(newVal) {
    if (queuedOperatingLogChanges.value.length === 0) {
      queuedAttendanceLogChanges.value.push(newVal);
      showAttendanceLogChangeQueue.value = true;
    }
  }

  function queueAttendanceLogQuestion(newVal) {
    if (queuedOperatingLogChanges.value.length > 0 || queuedAttendanceLogChanges.value.length > 0)
      return;

    if (
      queuedAttendanceLogQuestionId.value &&
      queuedAttendanceLogQuestionId.value != newVal.questionId
    )
      return;

    queuedAttendanceLogQuestionId.value = newVal.questionId;
    queuedAttendanceLogAnswers.value ||= [];
    queuedAttendanceLogAnswers.value.push(newVal.attendanceLogId);
    showAttendanceLogQuestionQueue.value = true;
  }

  function queueOperatingLogChange(day) {
    if (queuedAttendanceLogChanges.value.length === 0) {
      queuedOperatingLogChanges.value.push(day);
      showOperatingLogChangeQueue.value = true;
    }
  }

  async function updateOperatingLog(status) {
    processing.value = true;
    queuedOperatingLogChanges.value.forEach((day) => {
      selectedOperatingLog.value[`day_${day}`] = status ? status.code : null;
    });
    const { data } = await scopedApi().operating_log.update(
      selectedOperatingLog.value.id,
      selectedOperatingLog.value,
    );
    selectedOperatingLog.value = data;
    loadAttendanceLogs();
    operatingLogStatusDialog.value.close();
    processing.value = false;
    showOperatingLogChangeQueue.value = false;
    queuedOperatingLogChanges.value = [];
  }

  async function updateAttendanceLogsHours(newVal) {
    await updateAttendanceLogsStatus(newVal.hours);
  }

  async function updateAttendanceLogsAnswers(answer) {
    errors.value = [];
    processing.value = true;
    await Promise.all(
      queuedAttendanceLogAnswers.value.map(async (attendanceLogId) => {
        await scopedApi().attendance_log.update(attendanceLogId, answer);
      }),
    );
    attendanceLogQuestionDialog.value.close();
    processing.value = false;
    loadAttendanceLogs();
    showAttendanceLogQuestionQueue.value = false;
    queuedAttendanceLogAnswers.value = [];
    queuedAttendanceLogQuestionId.value = null;
  }

  async function updateAttendanceLogsStatus(updatedValue) {
    errors.value = [];
    processing.value = true;
    await Promise.all(
      queuedAttendanceLogChanges.value.map(async (ac) => {
        const newVal = {};
        newVal[`day_${ac.day}`] = updatedValue?.code || updatedValue || null;
        await scopedApi().attendance_log.update(ac.log.id, newVal);
      }),
    );
    attendanceLogHoursDialog.value?.close();
    attendanceLogStatusDialog.value?.close();
    processing.value = false;
    loadAttendanceLogs();
    showAttendanceLogChangeQueue.value = false;
    queuedAttendanceLogChanges.value = [];
  }

  function wasPresentOnDay(attendanceLog, day) {
    return presentStatusCodes.value.includes(attendanceLog[`day_${day}`]);
  }

  return {
    ATTENDANCE_LOG_HOURS_FIELDS,
    attendanceLogHoursDialog,
    attendanceLogStatusDialog,
    attendanceLogQuestionDialog,
    operatingLogStatusDialog,
    attendanceLogs,
    attendanceLogSchema,
    attendanceLogQuestions,
    attendanceTotals,
    errors,
    handleEditAttendanceLogStatus,
    loadPrograms,
    processing,
    providerId,
    operatingLogs,
    operatingLogSchema,
    queuedAttendanceLogQuestionId,
    queuedAttendanceLogAnswers,
    queuedOperatingLogChanges,
    presentStatusCodes,
    providers,
    programs,
    queuedAttendanceLogChanges,
    selectedOperatingLog,
    selectedProgram,
    showAttendanceLogChangeQueue,
    showAttendanceLogQuestionQueue,
    showOperatingLogChangeQueue,
    dailyTotals,
    selectedMonth,
    calculateAttendanceTotals,
    calculateDailyTotals,
    changeOperatingLog,
    changeOperatingLogById,
    changeProgram,
    changeProvider,
    clearAttendanceLog,
    clearOperatingLog,
    clearAttendanceChangeQueue,
    clearOperatingLogChangeQueue,
    clearAttendanceLogQuestionQueue,
    loadAttendanceLogs,
    loadAttendanceLogQuestions,
    loadOperatingLogs,
    loadProviders,
    openAttendanceLogQuestionDialog,
    queueAttendanceLogChange,
    queueAttendanceLogQuestion,
    queueOperatingLogChange,
    updateAttendanceLogsHours,
    updateOperatingLog,
    updateAttendanceLogsAnswers,
    updateAttendanceLogsStatus,
    wasPresentOnDay,
  };
}
