<template>
  <ResourceDialog
    ref="paymentDialog"
    :cancellable="false"
    :max-width="null"
    :title="title"
    save-button-text="Done"
    text-class="bg-super-light-blue"
    close-on-save
    closeable
    fullscreen
  >
    <template #form>
      <template v-if="payment">
        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-text>
            <v-row
              class="mb-3"
              dense
            >
              <LabeledCurrencyInput
                v-model="payment.amount"
                cols="3"
                message="Amount"
                dense
                readonly
              />

              <LabeledCurrencyInput
                v-model="payment.amount_adjusted"
                cols="3"
                message="Adjustments"
                dense
                readonly
              />

              <LabeledCurrencyInput
                v-model="payment.amount_paid"
                cols="3"
                message="Paid"
                dense
                readonly
              />

              <LabeledCurrencyInput
                v-model="payment.amount_unpaid"
                cols="3"
                message="Balance"
                dense
                readonly
              />
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-title>
            <v-row
              class="d-flex align-start"
              dense
            >
              <v-col cols="9">
                <h5>
                  {{ $t('Adjustments') }}
                </h5>
              </v-col>

              <v-col
                class="d-flex justify-end"
                cols="3"
              >
                <v-btn
                  v-if="payment.status != 'processed'"
                  @click="$refs.adjustmentDialog.open({ payment_id: payment.id })"
                  color="primary"
                  prepend-icon="add"
                >
                  {{ $t('Add adjustment') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <p class="fs-14 c-light-black mb-4">
              {{
                $t(
                  'Document any changes to the initial payment amount such as fines, refunds, or an increase in funding. Adjustments can be negative or postive.',
                )
              }}
            </p>

            <template v-if="adjustments.length > 0">
              <v-row>
                <TableHeader title="ID" />

                <TableHeader title="Amount" />

                <TableHeader title="Reason" />
              </v-row>

              <v-divider class="mt-2 mb-4" />

              <v-row
                v-for="adjustment in adjustments"
                :key="adjustment.id"
                class="d-flex align-center"
              >
                <TableCell
                  @click="openPaymentAdjustment(adjustment)"
                  :content="adjustment.id.split('-')[0]"
                  class="c-primary underlined pointer"
                />

                <TableCell
                  :content="adjustment.amount"
                  transform="currency"
                />

                <TableCell :content="adjustment.reason" />
              </v-row>
            </template>
          </v-card-text>
        </v-card>

        <v-card
          v-if="payment.status === 'processed'"
          class="mb-4"
          border
          tile
        >
          <v-card-title>
            <v-row
              class="d-flex align-start"
              dense
            >
              <v-col cols="9">
                <h5>
                  {{ $t('Overpayments') }}
                </h5>
              </v-col>

              <v-col
                class="d-flex justify-end"
                cols="3"
              >
                <v-btn
                  v-if="payment.status == 'processed'"
                  @click="$refs.adjustmentDialog.open({ payment_id: payment.id })"
                  color="primary"
                  prepend-icon="add"
                >
                  {{ $t('Add overpayment') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <p class="fs-14 c-light-black mb-4">
              {{ $t('Add any overpayment balances.') }}
            </p>

            <template v-if="overPayments.length > 0">
              <v-row>
                <TableHeader title="ID" />

                <TableHeader title="Amount" />
              </v-row>

              <v-divider class="mt-2 mb-4" />

              <v-row
                v-for="overPayment in overPayments"
                :key="overPayment.id"
                class="d-flex align-center"
              >
                <TableCell
                  :content="overPayment.id.split('-')[0]"
                  class="c-primary"
                />

                <TableCell
                  :content="overPayment.amount"
                  transform="currency"
                />
              </v-row>
            </template>
          </v-card-text>
        </v-card>

        <v-card
          class="mb-4"
          border
          tile
        >
          <v-card-title>
            <v-row dense>
              <v-col cols="9">
                <h5>
                  {{ $t('Transactions') }}
                </h5>
              </v-col>

              <v-col
                class="d-flex justify-end"
                cols="3"
              >
                <v-btn
                  v-if="payment.status != 'processed'"
                  @click="$refs.transferDialog.open({ payment_id: payment.id })"
                  color="primary"
                  prepend-icon="add"
                >
                  {{ $t('Add transaction') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <p class="fs-14 c-light-black mb-4">
              {{
                $t(
                  'A payment can be paid over multiple transactions. Document transactions paid towards the total payment amount.',
                )
              }}
            </p>

            <template v-if="transfers.length > 0">
              <v-row>
                <TableHeader title="ID" />

                <TableHeader title="Amount" />

                <TableHeader title="Date" />
              </v-row>

              <v-divider class="mt-2 mb-4" />

              <v-row
                v-for="transfer in transfers"
                :key="transfer.id"
                class="d-flex align-center"
              >
                <TableCell
                  @click="openTransfer(transfer)"
                  :content="transfer.id.split('-')[0]"
                  class="c-primary underlined pointer"
                />

                <TableCell
                  :content="transfer.amount"
                  transform="currency"
                />

                <TableCell :content="transfer.paid_date" />
              </v-row>
            </template>
          </v-card-text>
        </v-card>

        <v-card
          v-if="customPaymentFields.length > 0"
          border
          tile
        >
          <v-card-title dense>
            <v-row class="d-flex align-start">
              <v-col>
                <h5>
                  {{ $t('Additional information') }}
                </h5>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <LabeledTextfield
                v-for="field in customPaymentFields"
                v-model="payment.custom[field[0]]"
                @input="changed = true"
                :key="field[0]"
                :message="field[1].alias"
                cols="12"
                lg="4"
                md="6"
              />
            </v-row>
          </v-card-text>

          <v-card-actions v-show="changed">
            <v-btn
              @click="save"
              :loading="processing"
              color="primary"
            >
              {{ $t('Save') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card
          border
          tile
        >
          <v-card-title dense>
            <v-row class="d-flex align-start">
              <v-col>
                <h5>
                  {{ $t('Status') }}
                </h5>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <p>
              <template v-if="payment.status == 'approved'">
                <LongDate
                  :date="payment.scheduled_date"
                  prefix="This payment has been approved and will be processed on:"
                />
              </template>

              <template v-if="payment.status == 'draft'">
                <span>
                  {{ $t('This has been drafted and has not been approved.') }}
                </span>
              </template>

              <template v-if="payment.status == 'canceled'">
                <span>
                  {{ $t('This payment has been canceled.') }}
                </span>
              </template>

              <template v-if="payment.status == 'processed'">
                <span>
                  {{ $t('This payment has been processed.') }}
                </span>
              </template>
            </p>
          </v-card-text>

          <v-card-actions v-if="payment.status != 'processed'">
            <template v-if="payment.status != 'approved'">
              <v-btn
                @click="approve"
                :disabled="changed"
                :loading="processing"
                color="green"
                prepend-icon="check"
              >
                {{ $t('Approve payment') }}
              </v-btn>
            </template>

            <template v-if="payment.status != 'canceled'">
              <v-btn
                @click="cancel"
                :disabled="changed"
                :loading="processing"
                color="red"
                prepend-icon="cancel"
              >
                {{ $t('Cancel payment') }}
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </template>
    </template>
  </ResourceDialog>

  <ResourceDialog
    @save="createOrUpdatePaymentAdjustment"
    ref="adjustmentDialog"
    :fields="adjustmentFields"
    :processing="processing"
    title="Adjustment"
  />

  <ResourceDialog
    @save="createOrUpdateFundsTransfer"
    ref="transferDialog"
    :fields="transferFields"
    :max-width="800"
    :processing="processing"
    title="Transaction"
  />
</template>

<script>
import API from '@/shared/mixins/api';
import LabeledCurrencyInput from '@/shared/components/form/LabeledCurrencyInput.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDate from '@/shared/components/LongDate.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import TableCell from '@/shared/components/TableCell.vue';
import TableHeader from '@/shared/components/TableHeader.vue';

export default {
  compatConfig: { MODE: 3 },

  components: {
    LabeledCurrencyInput,
    LabeledTextfield,
    LongDate,
    ResourceDialog,
    TableCell,
    TableHeader,
  },

  mixins: [API],

  emits: ['error', 'chime', 'change'],

  data() {
    return {
      adjustments: [],
      changed: false,
      fundsTransferSchema: Object.values(this.$store.state.schemas).find(
        (schema) => schema.data_type === 'FundsTransfer',
      ),
      overPayments: [],
      payment: null,
      paymentAdjustmentSchema: Object.values(this.$store.state.schemas).find(
        (schema) => schema.data_type === 'PaymentAdjustment',
      ),
      paymentSchema: Object.values(this.$store.state.schemas).find(
        (schema) => schema.data_type === 'Payment',
      ),
      processing: false,
      title: null,
      transfers: [],
      transferSchema: null,
    };
  },

  computed: {
    adjustmentFields() {
      const fields = [
        {
          text: 'Amount',
          value: 'amount',
          type: 'currency',
          required: true,
        },
        {
          text: 'Reason',
          value: 'reason',
          rows: 3,
          required: true,
        },
      ];

      const customFields = Object.entries(
        this.paymentAdjustmentSchema.definition.properties.custom.properties,
      );
      customFields.forEach((field) => {
        fields.push({
          cols: '4',
          text: field[1].alias,
          value: ['custom', field[0]].join('.'),
        });
      });

      return fields;
    },

    customPaymentFields() {
      return Object.entries(this.paymentSchema.definition.properties.custom.properties);
    },

    transferFields() {
      const fields = [
        {
          cols: '6',
          text: 'Amount',
          value: 'amount',
          type: 'currency',
          required: true,
        },
        {
          cols: '6',
          text: 'Date',
          value: 'paid_date',
          type: 'date',
          required: true,
        },
      ];

      const customFields = Object.entries(
        this.fundsTransferSchema.definition.properties.custom.properties,
      );
      customFields.forEach((field) => {
        fields.push({
          cols: '4',
          text: field[1].alias,
          value: ['custom', field[0]].join('.'),
        });
      });

      return fields;
    },
  },

  methods: {
    async approve() {
      this.processing = true;
      const resp = await this.api.organization.payment
        .update(this.payment.id, { status: 'approved' })
        .catch((err) => this.$eventBus.$emit('error', err));
      this.processing = false;
      if (!resp) return;

      this.payment = resp.data;
      this.$eventBus.$emit('chime', 'Payment approved');
      this.$emit('change');
    },

    async cancel() {
      // eslint-disable-next-line no-alert
      if (!confirm(this.$t('Are you certain you want to cancel this payment?'))) return;

      this.processing = true;
      const resp = await this.api.organization.payment
        .update(this.payment.id, { status: 'canceled' })
        .catch((err) => this.$eventBus.$emit('error', err));
      this.processing = false;
      if (!resp) return;

      this.payment = resp.data;
      this.$eventBus.$emit('chime', 'Payment canceled');
      this.$emit('change');
    },

    createOrUpdatePaymentAdjustment(newVal) {
      if (!newVal.custom) {
        // eslint-disable-next-line no-param-reassign
        newVal.custom = {};
      }

      const customKeys = Object.keys(
        this.paymentAdjustmentSchema.definition.properties.custom.properties,
      );
      customKeys.forEach((ck) => {
        // eslint-disable-next-line no-param-reassign
        newVal.custom[ck] = newVal[['custom', ck].join('.')];
      });

      this.processing = true;
      this.api.organization.payment_adjustment.createOrUpdate(
        newVal,
        (response) => {
          if (response.data.payment?.id) {
            this.$eventBus.$emit(
              'chime',
              'This payment has been processed. A new payment will be created for this reconciliation.',
            );
            this.loadOverPayments();
            this.$refs.adjustmentDialog.close();
            this.processing = false;
            this.$emit('change');
          } else {
            this.loadAdjustments();
            this.loadPayment();
            this.$eventBus.$emit('chime', 'Saved');
            this.$refs.adjustmentDialog.close();
            this.$emit('change');
            this.processing = false;
          }
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    createOrUpdateFundsTransfer(newVal) {
      if (!newVal.custom) {
        // eslint-disable-next-line no-param-reassign
        newVal.custom = {};
      }

      const customKeys = Object.keys(
        this.fundsTransferSchema.definition.properties.custom.properties,
      );
      customKeys.forEach((ck) => {
        // eslint-disable-next-line no-param-reassign
        newVal.custom[ck] = newVal[['custom', ck].join('.')];
      });

      this.processing = true;
      this.api.organization.funds_transfer.createOrUpdate(
        newVal,
        () => {
          this.loadTransfers();
          this.loadPayment();
          this.$eventBus.$emit('chime', 'Saved');
          this.$refs.transferDialog.close();
          this.$emit('change');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    async loadOverPayments() {
      const resp = await this.api.organization.payment.index({
        originating_payment_id: this.payment.id,
      });
      this.overPayments = resp.data;
    },

    loadPayment() {
      this.api.organization.payment.get(this.payment.id, (resp) => {
        this.payment = resp.data;
      });
    },

    loadTransfers() {
      this.api.organization.funds_transfer.index({ payment_id: this.payment.id }, (resp) => {
        this.transfers = resp.data;
      });
    },

    loadAdjustments() {
      this.api.organization.payment_adjustment.index({ payment_id: this.payment.id }, (resp) => {
        this.adjustments = resp.data;
      });
    },

    async open(paymentId) {
      const payment = await this.api.organization.payment.get(paymentId);
      this.payment = payment.data;
      this.title = `Invoice #${payment.data.id.split('-')[0].toUpperCase()}`;
      this.loadOverPayments();
      this.loadTransfers();
      this.loadAdjustments();
      this.$refs.paymentDialog.open();
    },

    openPaymentAdjustment(adjustment) {
      const customKeys = Object.keys(
        this.paymentAdjustmentSchema.definition.properties.custom.properties,
      );
      customKeys.forEach((ck) => {
        // eslint-disable-next-line no-param-reassign
        adjustment[['custom', ck].join('.')] = adjustment.custom[ck];
      });

      this.$refs.adjustmentDialog.open(adjustment);
    },

    openTransfer(transfer) {
      const customKeys = Object.keys(
        this.fundsTransferSchema.definition.properties.custom.properties,
      );
      customKeys.forEach((ck) => {
        // eslint-disable-next-line no-param-reassign
        transfer[['custom', ck].join('.')] = transfer.custom[ck];
      });

      this.$refs.transferDialog.open(transfer);
    },

    save() {
      this.processing = true;
      this.api.organization.payment.update(
        this.payment.id,
        this.payment,
        (resp) => {
          this.payment = resp.data;
          this.changed = false;
          this.$eventBus.$emit('chime', 'Saved');
          this.$emit('change');
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },
  },
};
</script>
