<template>
  <ResourceDialog
    ref="dialog"
    :cancellable="false"
    :max-width="null"
    :title="title"
    save-button-text="Done"
    text-class="bg-super-light-blue"
    close-on-save
    closeable
    fullscreen
  >
    <template #form>
      <div class="px-3 d-flex flex-column">
        <div class="d-flex align-center mb-5">
          <h3>{{ $t('Statement ID:') }} {{ statement?.id.split('-')[0] }}</h3>
        </div>

        <v-row>
          <v-col cols="12">
            <v-card
              :class="summaryCardClass"
              border
              tile
            >
              <v-card-title class="fs-16">
                {{ t('Summary') }}
              </v-card-title>

              <v-card-text>
                <template
                  v-for="row in summaryRows"
                  :key="row"
                >
                  <v-divider
                    v-if="row.divider"
                    class="mt-4 mb-2"
                    color="grey"
                    opacity="0.75"
                  />

                  <v-row
                    v-if="row.title"
                    class="fs-14 mt-0 align-center"
                  >
                    <v-col
                      class="py-1"
                      cols="8"
                    >
                      <span>
                        {{ $t(row.title) }}
                      </span>
                    </v-col>

                    <v-col
                      class="d-flex justify-end py-1"
                      cols="4"
                    >
                      <v-chip
                        :color="row.valueColor"
                        class="pa-0"
                        variant="text"
                      >
                        {{ row.value }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <BillingPaymentIndex
              :can-open-payments="canOpenPayments"
              :default-providers="defaultProviders"
              :statement="statement"
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </ResourceDialog>
</template>

<script setup>
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import BillingPaymentIndex from '@/shared/components/billing/BillingPaymentIndex.vue';
import { currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  canOpenPayments: Boolean,
  defaultProviders: {
    type: Array,
    default: () => null,
  },
  summaryCardClass: {
    type: String,
    default: null,
  },
  api: {
    type: Object,
    default: null,
  },
});

const summaryRows = ref([]);
const statement = ref(null);
const dialog = ref(null);

function currencyWithSign(value) {
  const plainCurrency = currency(value);

  if (value > 0) return `+ ${plainCurrency}`;
  if (value < 0) return `- ${plainCurrency.substring(1)}`;

  return plainCurrency;
}

async function open(billingStatementId) {
  statement.value = (await props.api.get(billingStatementId)).data;

  const credits = statement.value.payments.reduce((accumulator, payment) => {
    if (payment.amount_unpaid > 0) return accumulator + payment.amount_unpaid;
    return accumulator;
  }, 0);

  const debits = statement.value.payments.reduce((accumulator, payment) => {
    if (payment.amount_unpaid < 0) return accumulator + payment.amount_unpaid;
    return accumulator;
  }, 0);

  summaryRows.value = [
    {
      title: 'Credits subtotal',
      value: currencyWithSign(credits),
    },
    { divider: true },
    {
      title: 'Debits subtotal',
      value: currencyWithSign(debits),
    },
    { divider: true },
    {
      title: 'Statement balance',
      value: currency(credits + debits),
    },
  ];

  dialog.value.open();
}

defineExpose({
  open,
});
</script>
