<template>
  <v-card
    v-if="enabled"
    border
    flat
    tile
  >
    <v-card-title
      class="fs-18"
      dense
    >
      <span>{{ $t(title) }}</span>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <div
        v-if="subtitle"
        class="fs-16 c-light-black mb-2"
      >
        {{ $t(subtitle) }}
      </div>
      <slot name="form" />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useStore } from 'vuex';

const props = defineProps({
  active: Boolean,
  fields: {
    type: Array,
    default: null,
  },
  hideTopDivider: {
    type: Boolean,
    default: false,
  },
  schemaId: {
    type: String,
    default: null,
  },
  subtitle: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const store = useStore();

const enabled = computed(() => {
  return props.active || (props.fields || []).some((field) => getProp(field).enabled);
});

function getProp(field) {
  if (!field) return null;
  return namespace(store.state.schemas[props.schemaId].definition.properties, field);
}

function namespace(object, path) {
  return path.split('.').reduce((value, index) => value[index], object);
}
</script>
