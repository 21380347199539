<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="highlights"
    :border="$role == 'specialist'"
    class="mb-4"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Highlights') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="my-4">
      <v-row>
        <LabeledSelect
          v-for="(key, index) in keys"
          v-model="provider.highlights[key]"
          @changed="calculateProgress"
          :key="index"
          :data-cy="'highlights-' + key"
          :field="'highlights.properties.' + key"
          :schema-id="provider.schema_id"
          global
        />
      </v-row>
      <v-row>
        <LabeledTextarea
          v-model="provider.other_programs_description"
          id="manager_include_other_highlights_info"
          :hard-lock="otherProgramsDescriptionDisabled()"
          :message="otherProgramsDescriptionTitle()"
          aria-label="Add any information you want to highlight"
          global
          tracked
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import { useStore } from 'vuex';

const store = useStore();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['back', 'progress']);

const keys = Object.keys(schemaProperties().highlights.properties);
const role = 'specialist'; // store.state.role;

onMounted(() => {
  calculateProgress();
});

function otherProgramsDescriptionDisabled() {
  return !schemaProperties().other_programs_description.editRoles?.includes(role);
}

function otherProgramsDescriptionTitle() {
  return schemaProperties().other_programs_description.alias || 'Other program information:';
}

function calculateProgress() {
  // If anything has been updated, consider highlights as 100% complete
  const progress = keys.some(
    (key) => props.provider.highlights[key] && props.provider.highlights[key].length > 0,
  )
    ? 100
    : 0;
  emit('progress', progress);
}

function schemaProperties() {
  return store.state.schemas[props.provider.schema_id].definition.properties;
}
</script>
