<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="description"
    :border="$role == 'specialist'"
    class="mb-4"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t(`Description of ${terms.program.toLowerCase()}`) }}
      </h2>
    </v-card-title>

    <v-divider />

    <v-card-text class="my-4">
      <v-row>
        <LabeledTextarea
          v-model="provider.description"
          @change="calculateProgress"
          id="manager_program_description_mission"
          :aria-label="`Tell us about your ${terms.program.toLowerCase()} description and mission`"
          :message="descriptionMessage"
          :schema-id="provider.schema_id"
          data-cy="program_description"
          field="description"
          global
          tracked
        />
        <!-- eslint-disable-next-line max-len -->
        <template
          v-if="
            $store.state.schemas[provider.schema_id].definition.properties.categories.items?.enum
              ?.length > 0
          "
        >
          <LabeledSelect
            v-model="provider.categories"
            :schema-id="provider.schema_id"
            aria-label="What types of resources are offered?"
            data-cy="categories_field"
            field="categories"
            message="What types of resources are offered?"
            global
          />
        </template>
        <LabeledTextarea
          v-model="provider.public_notice"
          id="manager_program_description_mission"
          :schema-id="provider.schema_id"
          aria-label="Public notice"
          data-cy="public_notice"
          field="public_notice"
          message="Public notice"
          rows="2"
          global
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import useTerms from '@/shared/composables/useTerms';

const { terms } = useTerms();

const props = defineProps({
  provider: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['back', 'progress']);

const descriptionMessage = computed(() => {
  return (
    `Tell us about your ${terms.value.program.toLowerCase()} ` +
    '(i.e. mission statement, philosophy, important info, etc.)'
  );
});

function calculateProgress() {
  emit('progress', props.provider.description ? 100 : 0);
}
</script>
