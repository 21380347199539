<template>
  <td
    :title="statusTitle"
    class="bb-1 py-2 br-1 bc-very-light-grey"
  >
    <div
      @click="handleClick"
      :class="classes"
      :style="styles"
      class="rounded d-flex align-center justify-center h-100pc"
    >
      {{ code }}
    </div>
  </td>
</template>

<script setup>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  day: {
    type: Number,
    default: null,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  log: {
    type: Object,
    default: null,
  },
  operatingLog: {
    type: Object,
    default: null,
  },
  queue: {
    type: Array,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  statuses: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['click']);

const logDay = computed(() => `day_${props.day}`);

const code = computed(() => props.log[logDay.value]);

const queued = computed(
  () => !!props.queue.find((ac) => ac.log.id === props.log.id && ac.day === props.day),
);

const open = computed(() => {
  const dayCode = props.operatingLog[logDay.value];
  if (dayCode) {
    return !store.state.config.operating_statuses.find((os) => os.code === dayCode).closed;
  }
  return false;
});

const status = computed(() => props.statuses.find((status) => status.code === code.value));

const classes = computed(() => {
  const ary = [];
  if (!props.log[logDay.value]) {
    ary.push('bg-very-light-grey');
  }

  if (queued.value) {
    ary.push('shadowed');
  }

  if (open.value && !props.readonly) {
    ary.push('pointer');
  }

  return ary.join(' ');
});

const styles = computed(() => {
  let styles = '';
  if (status.value) {
    styles += `background-color: ${status.value.color}`;
  }
  if (props.invalid) {
    styles += ' border: 2px solid #B30036';
  }
  return styles;
});

const statusTitle = computed(() => {
  if (status.value) {
    return t(status.value.title);
  }
  return t('unknown');
});

const handleClick = () => {
  if (open.value && !props.readonly && !queued.value) {
    emit('click');
  }
};
</script>
