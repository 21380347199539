<template>
  <div>
    <v-row dense>
      <v-col
        class="py-0"
        cols="12"
      >
        <PublicNotice
          v-if="program.public_notice"
          :public-notice="program.public_notice"
          small
        />

        <div
          v-if="enrollmentCount != null"
          class="d-flex align-start my-3"
        >
          <v-icon
            class="me-3"
            color="primary"
            icon="chair_alt"
            size="24"
          />
          <template v-if="$role == 'specialist'">
            <span
              @click="updateQuery({ tab: 'students', programId: program.id })"
              class="c-primary underlined pointer"
            >
              {{ enrollmentCount }} {{ $t('enrolled') }}
            </span>
          </template>
          <template v-else>
            <router-link
              :to="{
                name: 'EnrollmentIndex',
                query: { providerId: $route.params.providerId, programId: program.id },
              }"
            >
              {{ enrollmentCount }} {{ $t('enrolled') }}
            </router-link>
          </template>

          <template v-if="seatCount != null && seatCount > 0">
            <div class="ms-2">
              <span class="me-1">/</span>
              {{ seatCount }} {{ $t('seats') }}
            </div>
          </template>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            icon="child_care"
            size="24"
          />
          <div
            v-if="program.grade_max"
            class="d-inline-block fs-16 fw-400"
          >
            {{ $t('Grades') }}: {{ $t(gradeMinText) }} - {{ $t(gradeMaxText) }}
          </div>
          <div
            v-if="!program.grade_max && program.age_max"
            class="d-inline-block fs-16 fw-400"
          >
            {{ $t('Ages:') }} {{ ageMinText }} - {{ ageMaxText }}
          </div>
          <div
            v-if="!program.grade_max && !program.age_max"
            class="d-inline-block fs-16 fw-400"
          >
            {{ $t('Age groups not available') }}
          </div>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            icon="date_range"
            size="24"
          />

          <div
            v-if="program.hours_description && program.hours_description.length > 0"
            class="d-inline-block"
          >
            <div
              v-if="program.season"
              v-text="$t(program.season)"
              class="fs-16 fw-400 mb-1"
            />
            <HoursChunks :chunks="program.hours_chunks" />
          </div>

          <div
            v-else
            class="d-inline-block"
          >
            <div class="fs-16 fw-400">
              <span
                v-if="program.season"
                v-text="$t(program.season) + ' - '"
              />
              <span>{{ $t('same as location hours') }}</span>
            </div>
          </div>

          <div
            v-if="!program.regular_hours && !program.hours_description"
            class="d-inline-block c-light-black fs-16 fw-400"
          >
            <div
              v-if="program.season"
              v-text="$t(program.season)"
              class="fs-16 fw-400"
            />
            <div v-else>
              {{ $t('No program schedule information') }}
            </div>
          </div>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            icon="monetization_on"
            size="24"
          />

          <div
            v-if="program.is_free"
            class="d-inline-block"
          >
            <span class="fw-400 fs-16">
              {{ $t('Free') }}
            </span>
            <span
              v-if="program.is_donation_based"
              class="fs-16 fw-400"
            >
              {{ `- ${$t('donations accepted')}` }}
            </span>
          </div>

          <div
            v-else
            class="d-inline-block"
          >
            <template v-if="hasRates">
              <div
                v-if="program.rate_hourly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_hourly) }} {{ $t('paid hourly') }}
              </div>
              <div
                v-if="program.rate_daily"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_daily) }} {{ $t('paid daily') }}
              </div>
              <div
                v-if="program.rate_weekly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_weekly) }} {{ $t('paid weekly') }}
              </div>
              <div
                v-if="program.rate_monthly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_monthly) }} {{ $t('paid monthly') }}
              </div>
              <div
                v-if="program.rate_bimonthly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_bimonthly) }} {{ $t('paid twice a month') }}
              </div>
              <div
                v-if="program.rate_biweekly"
                class="fs-16 fw-400"
              >
                {{ currency(program.rate_biweekly) }} {{ $t('paid every two weeks') }}
              </div>
            </template>

            <div
              v-if="!hasRates && program.rate"
              class="fw-400 fs-16"
            >
              {{ currency(program.rate) }} {{ programRateText }}
            </div>

            <div
              v-if="!hasRates && !program.rate"
              class="fs-16 fw-400 c-light-black"
            >
              {{ $t('No cost information') }}
            </div>
          </div>
        </div>

        <div class="d-flex align-start my-3">
          <v-icon
            class="me-3"
            color="primary"
            icon="person_pin"
            size="24"
          />

          <div
            v-if="!program.contact_name"
            class="d-inline-block fs-16 fw-400 c-light-black"
          >
            {{ $t('No program contact') }}
          </div>

          <div
            v-else
            class="d-inline-block"
          >
            <div class="fs-16 fw-400">
              {{ `${$t('Contact')} ${program.contact_name}` }}
            </div>
            <div
              v-text="
                [program.contact_email, program.contact_phone].filter((item) => item).join(' | ')
              "
              class="fs-16 fw-400"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="program.description"
      dense
    >
      <v-col>
        <div
          @click="descriptionVisible = !descriptionVisible"
          id="manager_program_description"
          class="c-primary underlined pointer fs-16 fw-400"
          tracked
        >
          {{ $t('Description') }}
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="descriptionVisible && program.description"
      dense
    >
      <v-col>
        <div
          v-text="program.description"
          class="fs-16 fw-400"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import HoursChunks from '@/public/components/provider/HoursChunks.vue';
import PublicNotice from '@/shared/components/PublicNotice.vue';
import { currency } from '@/plugins/filters';
import useAgeCalculations from '@/shared/composables/useAgeCalculations';
import useAssets from '@/shared/composables/useAssets';
import { useI18n } from 'vue-i18n';
import useRouterHelper from '@/shared/composables/useRouterHelper';

const { assets } = useAssets();
const { monthsToAgeString } = useAgeCalculations();
const { updateQuery } = useRouterHelper();
const { t } = useI18n();

const props = defineProps({
  dashboard: {
    type: Object,
    default: null,
  },
  program: {
    type: Object,
    default: null,
  },
});

const descriptionVisible = ref(false);

const ageMaxText = computed(() => {
  return monthsToAgeString(props.program.age_max);
});

const ageMinText = computed(() => {
  return monthsToAgeString(props.program.age_min);
});

const enrollmentCount = computed(() => {
  if (!props.dashboard) return null;

  return (
    props.dashboard.enrollment_program_groups.find((group) => group.program_id === props.program.id)
      ?.count || 0
  );
});

const gradeMaxText = computed(() => {
  return assets.programs.grades[props.program.grade_max].text;
});

const gradeMinText = computed(() => {
  return assets.programs.grades[props.program.grade_min].text;
});

const hasRates = computed(() => {
  return (
    props.program.rate_hourly ||
    props.program.rate_daily ||
    props.program.rate_weekly ||
    props.program.rate_monthly ||
    props.program.rate_bimonthly ||
    props.program.rate_biweekly
  );
});

const programRateText = computed(() => {
  return props.program.period ? [t('paid'), t(props.program.period).toLowerCase()].join(' ') : '';
});

const seatCount = computed(() => {
  if (!props.dashboard) return null;

  return (
    props.dashboard.enrollment_program_groups.find((group) => group.program_id === props.program.id)
      ?.seats || 0
  );
});
</script>
