<template>
  <ResourceDialog
    @close="$emit('close')"
    @save="$emit('save', answer)"
    ref="dialog"
    :max-width="600"
    :processing="processing"
    closeable
  >
    <template #form>
      <div class="ta-center">
        <QuestionWithField
          v-model="answer"
          :elevation="0"
          :hide-actions="true"
          :outlined="false"
          :paddingless="true"
          :processing="processing"
          :question="question"
          :schema="schemaDefinition"
        />
      </div>
    </template>
  </ResourceDialog>
</template>

<script setup>
import QuestionWithField from '@/shared/components/form/QuestionWithField.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';

defineProps({
  processing: {
    type: Boolean,
    default: false,
  },
  schemaDefinition: {
    type: Object,
    default: null,
  },
});

defineEmits(['close', 'save']);

const dialog = ref(null);
const answer = ref(getBlankAnswer());
const question = ref(null);

function close() {
  dialog.value.close();
}

function getBlankAnswer() {
  return { custom: {} };
}

function open({ question: questionData }) {
  answer.value = getBlankAnswer();
  question.value = questionData;
  dialog.value.open();
}

defineExpose({
  close,
  open,
});
</script>
