<template>
  <div v-if="confirmed != null">
    <MFAEnrollmentDialog
      @change="check"
      :closeable="confirmed"
      eager
    />
  </div>
</template>

<script setup>
import MFAEnrollmentDialog from '@/shared/components/MFAEnrollmentDialog.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const confirmed = ref(null);
const store = useStore();
const router = useRouter();

const check = () => {
  if (store.state.profile.workos_mfa_factor_confirmed) {
    confirmed.value = true;
    router.push({ name: 'Dashboard' });
  } else {
    confirmed.value = false;
  }
};

onMounted(check);
</script>
