<template>
  <v-card flat>
    <v-card-text class="pa-0 ox-scroll">
      <div
        aria-labelledby="caption"
        class="fs-16"
        role="region"
      >
        <table
          class="attendance mb-16 noselect"
          freeze-first-column-row
        >
          <thead>
            <tr>
              <th class="ta-left b-1 bc-very-light-grey py-2 px-2">
                {{ t('Student') }}
              </th>
              <th class="bb-1 bt-1 br-1 bc-very-light-grey py-2">
                {{ t('ID') }}
              </th>
              <th class="bb-1 bt-1 br-1 bc-very-light-grey py-2">
                {{ t('DOB') }}
              </th>
              <th class="bb-1 bt-1 br-1 bc-very-light-grey py-2">
                {{ t('Start date') }}
              </th>
              <th class="bb-1 bt-1 br-1 bc-very-light-grey py-2">
                {{ t('End date') }}
              </th>

              <th
                v-for="question in attendanceLogQuestions"
                :key="question.id"
                :title="t(question.title)"
                class="bb-1 bt-1 br-1 bc-very-light-grey py-2"
              >
                <div
                  class="c-black py-1 rounded d-flex align-center justify-center h-100pc"
                  role="cell"
                >
                  {{ t(question.title) }}
                </div>
              </th>

              <template v-if="isDailyAttendanceEnabled()">
                <OperatingLogDay
                  v-for="day in operatingLog.total_days"
                  @click="emit('queue:operating-log-change', day)"
                  :key="day"
                  :day="day"
                  :log="operatingLog"
                  :queue="queuedOperatingLogChanges"
                  :readonly="!!operatingLog.submitted_at"
                  :statuses="$store.state.config.operating_statuses"
                />

                <th class="bt-1 br-1 bb-1 bc-very-light-grey ta-center px-2 py-2">
                  {{ operatingLog.total_open }}
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="attendanceLog in getAttendanceLogs()"
              :key="attendanceLog.id"
            >
              <td :class="logClasses(attendanceLog)">
                <ActionMenu
                  v-if="$role == 'specialist'"
                  @click:action:delete="emit('delete:attendance-log', attendanceLog)"
                  :button-icon-size="16"
                  :button-title="attendanceLog.name"
                  :items="[{ avatar: 'delete', event: 'delete', title: 'Remove' }]"
                  button-class="ms-1"
                  button-icon-side="right"
                  hover-underline
                />
                <span v-else>{{ attendanceLog.name }}</span>
              </td>

              <td class="bb-1 br-1 bc-very-light-grey">
                {{ attendanceLog?.meta?.child?.external_id }}
              </td>
              <td class="bb-1 br-1 bc-very-light-grey">
                {{ attendanceLog?.meta?.child?.dob }}
              </td>
              <td class="bb-1 br-1 bc-very-light-grey">
                {{ attendanceLog?.meta?.enrollment?.start_date }}
              </td>
              <td class="bb-1 br-1 bc-very-light-grey">
                {{ attendanceLog?.meta?.enrollment?.end_date }}
              </td>

              <AttendanceLogQuestion
                v-for="question in attendanceLogQuestions"
                @click="
                  emit('queue:attendance-log-question', {
                    questionId: question.id,
                    attendanceLogId: attendanceLog.id,
                  })
                "
                :key="`${question.id}-${attendanceLog.id}`"
                :attendance-log="attendanceLog"
                :invalid="isInvalidQuestion(attendanceLog, question)"
                :operating-log="operatingLog"
                :question="question"
                :queued-attendance-log-answers="queuedAttendanceLogAnswers"
                :queued-attendance-log-question-id="queuedAttendanceLogQuestionId"
                :readonly="!!operatingLog.submitted_at"
                :schema-definition="attendanceLogSchema.definition"
                :statuses="$store.state.config.attendance_statuses"
              />

              <template v-if="isDailyAttendanceEnabled()">
                <AttendanceLogDay
                  v-for="day in operatingLog.total_days"
                  @click="emit('queue:attendance-log-change', { log: attendanceLog, day: day })"
                  :key="`${attendanceLog.id}-${day}`"
                  :day="day"
                  :invalid="isInvalidDay(attendanceLog, day)"
                  :log="attendanceLog"
                  :operating-log="operatingLog"
                  :queue="queuedAttendanceLogChanges"
                  :readonly="!!operatingLog.submitted_at"
                  :statuses="$store.state.config.attendance_statuses"
                />
                <td class="bb-1 br-1 bc-very-light-grey ta-center px-3">
                  {{ attendanceTotals[attendanceLog.id] }}
                </td>
              </template>
            </tr>
            <template v-if="isDailyAttendanceEnabled()">
              <tr>
                <td class="ta-left b-1 bc-very-light-grey px-2">
                  {{ t('Totals') }}
                </td>

                <td class="bc-very-light-grey br-1 bt-1 bb-1">
                  <!-- Empty for 'Child ID' col -->
                </td>
                <td class="bc-very-light-grey br-1 bt-1 bb-1">
                  <!-- Empty for 'Date of birth' col -->
                </td>
                <td class="bc-very-light-grey br-1 bt-1 bb-1">
                  <!-- Empty for 'Enrollment start date' col -->
                </td>
                <td class="bc-very-light-grey br-1 bt-1 bb-1">
                  <!-- Empty for 'Enrollment end date' col -->
                </td>
                <td
                  v-for="index in attendanceLogQuestions"
                  :key="['question-total-empty', index].join('-')"
                  class="bc-very-light-grey br-1 bt-1 bb-1"
                >
                  <!-- Empty for attendance log questions cols -->
                </td>
                <td
                  v-for="day in operatingLog.total_days"
                  :key="['total', day].join('-')"
                  class="bc-very-light-grey bt-1 br-1 bb-1"
                >
                  <div
                    class="d-flex align-center justify-center h-100pc"
                    role="cell"
                  >
                    {{ dailyTotals[day] || 0 }}
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <template v-if="submitRevision">
        <p class="fs-14 mt-4">
          <LongDateTime
            :date="submitRevision.created_at"
            :prefix="operatingLog.submitted_at ? 'Submitted on' : 'Previously submitted on'"
            :suffix="`${t('by')}  ${submitRevision.author_name}`"
          />
        </p>
      </template>
    </v-card-text>
  </v-card>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AttendanceLogDay from '@/manager/components/AttendanceLogDay.vue';
import AttendanceLogQuestion from '@/shared/components/attendance-logs/AttendanceLogQuestion.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import OperatingLogDay from '@/manager/components/OperatingLogDay.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  attendanceLogSchema: {
    type: Object,
    default: null,
  },
  attendanceLogs: {
    type: Array,
    default: null,
  },
  attendanceLogQuestions: {
    type: Array,
    default: null,
  },
  attendanceTotals: {
    type: Object,
    default: null,
  },
  dailyTotals: {
    type: Object,
    default: null,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  operatingLog: {
    type: Object,
    default: null,
  },
  operatingLogSchema: {
    type: Object,
    default: null,
  },
  queuedAttendanceLogChanges: {
    type: Array,
    default: null,
  },
  queuedAttendanceLogQuestionId: {
    type: String,
    default: null,
  },
  queuedAttendanceLogAnswers: {
    type: Array,
    default: null,
  },
  queuedOperatingLogChanges: {
    type: Array,
    default: null,
  },
  submitRevision: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits([
  'delete:attendance-log',
  'queue:attendance-log-change',
  'queue:attendance-log-question',
  'queue:operating-log-change',
]);

const getAttendanceLogs = () => {
  return props.attendanceLogs;
};

const isDailyAttendanceEnabled = () => {
  return !props.operatingLogSchema?.meta.disable_daily_attendance;
};

const isInvalidDay = (attendanceLog, day) => {
  return props.errors.includes([attendanceLog.id, day].join('-'));
};

const isInvalidQuestion = (attendanceLog, question) => {
  return props.errors.includes([attendanceLog.id, question.id].join('-'));
};

const logClasses = (attendanceLog) => {
  let classes = 'bb-1 ta-left bl-1 br-1 bc-very-light-grey py-2 px-2';
  const containsErrors = props.errors[attendanceLog.id];
  if (containsErrors) {
    classes += ' b-4-red';
  }
  return classes;
};
</script>
