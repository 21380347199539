<template>
  <th
    @click.stop.prevent="handleClick"
    :title="statusTitle"
    class="bb-1 bt-1 br-1 bc-very-light-grey py-2"
  >
    <div
      :class="classes"
      :style="styles"
      class="c-white py-1 rounded d-flex align-center justify-center h-100pc"
    >
      {{ day }}
    </div>
  </th>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  day: {
    type: Number,
    default: null,
  },
  log: {
    type: Object,
    default: null,
  },
  queue: {
    type: Array,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  statuses: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['click']);

const classes = computed(() => {
  const ary = [];

  if (!props.readonly) {
    ary.push('pointer');
  }

  if (queued.value) {
    ary.push('shadowed');
  }

  return ary.join(' ');
});

const logDay = computed(() => `day_${props.day}`);

const code = computed(() => props.log[logDay.value]);

const queued = computed(() => !!props.queue.find((day) => day === props.day));

const status = computed(() => props.statuses.find((status) => status.code === code.value));

const styles = computed(() => {
  if (status.value) {
    return `background-color: ${status.value.color}`;
  }
  return '';
});

const statusTitle = computed(() => {
  const currentStatus = t(status.value?.title || 'Unknown');
  return currentStatus;
});

const handleClick = () => {
  if (!props.readonly && !queued.value) {
    emit('click');
  }
};
</script>
