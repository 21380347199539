<template>
  <div>
    <v-card
      v-if="items"
      class="bt-1 br-1 bl-1 bc-outlined-gray"
      tile
    >
      <v-card-title class="fs-16">
        {{ t('Items') }}
      </v-card-title>
    </v-card>

    <v-data-table-server
      v-if="items"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="100"
      :loading="processing"
      class="b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No payments found"
      fixed-header
      return-object
    >
      <template #item.id="{ item }">
        <span
          v-if="canOpenPayments"
          @click="openPayment(item.id)"
          class="cursor-pointer underlined"
        >
          {{ item.id.split('-')[0].toUpperCase() }}
        </span>

        <span v-else>
          {{ item.id.split('-')[0].toUpperCase() }}
        </span>
      </template>

      <template #item.scheduled_date="{ item }">
        <LongDate :date="item.scheduled_date" />
      </template>

      <template #item.adjusted_amount="{ item }">
        <span>
          {{ currency(item.amount_unpaid) }}
        </span>
      </template>

      <template #item.amount_unpaid="{ item }">
        <span>
          {{ currency(item.amount_unpaid) }}
        </span>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <PaymentEditor
      v-if="canOpenPayments"
      ref="paymentEditor"
    />
  </div>
</template>

<script setup>
import PaymentEditor from '@/specialist/components/PaymentEditor.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import LongDate from '@/shared/components/LongDate.vue';
import { currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const items = ref(null);
const processing = ref(null);
const totalItemCount = ref(null);
const paymentEditor = ref(null);
const props = defineProps({
  canOpenPayments: Boolean,
  defaultProviders: {
    type: Array,
    default: () => null,
  },
  statement: {
    type: Object,
    default: () => {},
  },
});

const tableHeaders = computed(() => {
  return [
    {
      title: t('ID'),
      value: 'id',
    },
    {
      title: t('Note'),
      value: 'note',
    },
    {
      title: t('Date'),
      value: 'scheduled_date',
    },
    {
      title: t('Adjusted Amount'),
      value: 'adjusted_amount',
      align: 'end',
    },
    {
      title: t('Unpaid Balance'),
      value: 'amount_unpaid',
      align: 'end',
    },
  ];
});

async function load() {
  items.value = props.statement.payments;
  totalItemCount.value = items.value.length;
}

function openPayment(paymentId) {
  paymentEditor.value.open(paymentId);
}

onMounted(load);
</script>
