<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="licensing"
    :border="role == 'specialist'"
    class="mb-4"
    data-cy="licensing-card"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Licensing & accreditations') }}
      </h2>
    </v-card-title>

    <v-divider />

    <v-card-text class="my-4">
      <v-row
        v-for="field in $store.state.schemas[provider.schema_id].meta.licensing.prepend"
        :key="field"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :schema-id="provider.schema_id"
          :show-title="true"
          class="fs-16"
          global
        />
      </v-row>
      <v-row>
        <LabeledTextfield
          v-model="provider.license_business_name"
          :schema-id="provider.schema_id"
          aria-label="License business name"
          cols="12"
          field="license_business_name"
          global
        />
        <LabeledSimpleSelect
          v-model="provider.age_group_ids"
          :items="ageGroupsItems"
          :schema-id="provider.schema_id"
          cols="12"
          field="age_group_ids"
          chips
          global
          multiple
        />
        <LabeledSimpleSelect
          v-if="
            $store.state.schemas[provider.schema_id].definition.properties.quality_rating_score
              .enabled
          "
          v-model="provider.quality_rating_score"
          id="manager_quality_rating_score_click"
          :hard-lock="!$store.state.profile.org_quality_rating_access"
          :items="qualityRatingItems"
          :message="$store.state.site.ratings.name"
          aria-label="Quality rating score"
          cols="12"
          md="6"
          global
          tracked
        />
        <LabeledTextfield
          v-model="provider.state_provider_id"
          id="manager_license_number_click"
          :schema-id="provider.schema_id"
          aria-label="State provider ID"
          cols="12"
          field="state_provider_id"
          md="6"
          global
          tracked
        />
        <LabeledTextfield
          v-model="provider.state_location_id"
          id="manager_state_location_id_click"
          :schema-id="provider.schema_id"
          aria-label="State location ID"
          cols="12"
          field="state_location_id"
          md="6"
          global
          tracked
        />
        <LabeledTextfield
          v-model="provider.license_url"
          id="manager_license_url_click"
          :schema-id="provider.schema_id"
          aria-label="License URL"
          cols="12"
          field="license_url"
          md="6"
          global
          tracked
        />
        <LabeledTextfield
          v-model="provider.license_capacity"
          id="manager_license_capacity_click"
          :schema-id="provider.schema_id"
          aria-label="License capacity"
          cols="12"
          field="license_capacity"
          md="6"
          global
          tracked
        />
        <LabeledTextfield
          v-model="provider.license_start_date"
          id="manager_license_start_date_click"
          :schema-id="provider.schema_id"
          aria-label="License start date"
          cols="12"
          field="license_start_date"
          md="6"
          global
          tracked
        />
        <LabeledTextfield
          v-model="provider.license_expiration_date"
          :schema-id="provider.schema_id"
          aria-label="License expiration date"
          cols="12"
          field="license_expiration_date"
          md="6"
          global
        />
        <LabeledSelect
          v-model="provider.license_type"
          :schema-id="provider.schema_id"
          aria-label="License type"
          cols="12"
          field="license_type"
          md="6"
          global
        />
      </v-row>

      <v-row
        v-for="(field, index) in $store.state.schemas[provider.schema_id].meta.licensing.append"
        :key="index"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :schema-id="provider.schema_id"
          :show-title="true"
          class="fs-16"
          global
        />
      </v-row>

      <div class="mt-5">
        <a
          v-if="!provider.full_license && provider.license_url"
          :href="provider.license_url"
          class="td-none c-primary fw-600"
          style="text-decoration: none"
          target="_blank"
        >
          <span class="me-1">{{ $t('View certificates and inspection reports') }}</span>
          <v-icon color="primary">arrow_right</v-icon>
        </a>
        <div
          v-if="provider.full_license"
          @click="showLicense()"
          class="c-primary fw-600 pointer"
        >
          <span class="me-1">{{ $t('View certificates and inspection reports') }}</span>
          <v-icon color="primary"> arrow_drop_down </v-icon>
        </div>
      </div>

      <v-row v-if="license">
        <v-col class="pb-1">
          <ProviderLicense :license="license" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import publicApi from '@/shared/services/api/public-api';
import CustomField from '@/shared/components/CustomField.vue';
import ProviderLicense from '@/shared/components/provider/ProviderLicense.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import { useStore } from 'vuex';
const store = useStore();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

defineEmits(['back']);

const role = store.state.role;

const license = ref(null);

const ageGroupsItems = computed(() =>
  store.state.ageGroups.map((ag) => ({ text: ag.name, value: ag.id })),
);
const qualityRatingItems = computed(() =>
  store.state.site.ratings.text.map((i, index) => ({ text: i.title, value: index + 1 })),
);

function showLicense() {
  if (license.value) {
    license.value = null;
  } else {
    publicApi.provider.license.get(props.provider.id, (resp) => {
      license.value = resp.data;
    });
  }
}
</script>

<style scoped>
a span:hover {
  text-decoration: underline !important;
}
</style>
