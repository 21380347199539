<template>
  <v-card
    :key="member.id"
    border
    tile
  >
    <v-card-title v-if="member.id">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col class="d-flex align-center">
          <v-avatar
            v-if="member.first_name"
            class="fs-18 c-white me-4"
            color="primary"
            size="40"
          >
            {{ [member.first_name[0], member.last_name[0]].join('').toUpperCase() }}
          </v-avatar>

          <div class="w-100pc">
            <div
              v-text="
                member.first_name
                  ? [member.first_name, member.last_name].join(' ')
                  : $t('Unknown name')
              "
              class="fs-16 fw-600 mb-0 w-100pc word-break-word"
            />

            <div
              v-text="member.email"
              class="fs-14 c-light-black w-100pc word-break-word"
            />
          </div>
        </v-col>

        <v-col class="d-flex justify-end">
          <template v-if="!readOnly">
            <ActionMenu
              @click:action:dismiss="dismiss"
              :items="memberActionItems"
              button-icon="more_vert"
              left
            />
          </template>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row>
        <LabeledTextfield
          v-model="member.first_name"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          message="First name"
        />

        <LabeledTextfield
          v-model="member.last_name"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          message="Last name"
        />

        <LabeledTextfield
          v-model="member.email"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          message="Email"
        />

        <LabeledSimpleSelect
          v-model="member.business_role"
          @input="changed = true"
          :hard-lock="readOnly"
          :items="['Admin', 'Member']"
          cols="4"
          message="Role"
        />

        <LabeledTextfield
          v-model="member.title"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          message="Job Title"
        />

        <LabeledAutocomplete
          v-model="member.provider_ids"
          @input="changed = true"
          :hard-lock="readOnly"
          :items="providers"
          cols="4"
          item-title="name"
          item-value="id"
          message="Locations"
          placeholder="All locations"
          chips
          deletable-chips
          multiple
        />

        <LabeledDatePicker
          v-model="member.start_date"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          message="Employment start date"
        />

        <LabeledDatePicker
          v-model="member.end_date"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          message="Employment end date"
        />

        <LabeledSwitch
          v-model="member.business_messages_access"
          @input="changed = true"
          :hard-lock="readOnly"
          cols="4"
          subtitle="Can access instant messages and respond to messages from organization staff and families."
          title="Messaging"
        />
      </v-row>
    </v-card-text>
    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="member.id"
        @click="update"
        :disabled="changed == false"
        :loading="processing"
        color="primary"
      >
        {{ $t('Update Employee') }}
      </v-btn>
      <v-btn
        v-else
        @click="create"
        :disabled="changed == false"
        :loading="processing"
        color="primary"
      >
        {{ $t('Create Employee') }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <ConfirmDialog ref="confirmDialog" />
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/manager/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';

const eventBus = useEventBus();
const store = useStore();

const props = defineProps({
  member: {
    type: Object,
    default: null,
  },
  providers: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['change']);

const changed = ref(false);
const confirmDialog = ref(null);
const memberActionItems = ref([{ title: 'Dismiss', avatar: 'delete', event: 'delete' }]);
const processing = ref(false);

const readOnly = computed(() => {
  return store.state.profile.business_role !== 'Admin';
});

function buildEmploymentData() {
  props.member.employments_attributes = [
    {
      business_messages_access: props.member.business_messages_access,
      end_date: props.member.end_date,
      id: props.member.employments?.[0]?.id,
      provider_ids: props.member.provider_ids || [],
      start_date: props.member.start_date,
      title: props.member.title,
    },
  ];
}

function processChange() {
  eventBus.chime('Saved');
  processing.value = false;
  changed.value = false;
  emit('change');
}

async function dismiss() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to dismiss this staff member? This will terminate their employment record.',
  );
  if (!confirm) return;

  const dismissParams = {
    member: {
      employments_attributes: [
        { id: props.member.employments?.[0]?.id, end_date: props.member.end_date },
      ],
    },
  };
  await Api.manager.member.update(props.member.id, dismissParams);
  processChange();
}

async function create() {
  processing.value = true;
  buildEmploymentData();
  await Api.manager.member.create(props.member);
  processChange();
}

async function update() {
  processing.value = true;
  buildEmploymentData();
  await Api.manager.member.update(props.member.id, props.member);
  processChange();
}
</script>
