import { EventBus } from '@/plugins/event-bus'; // TODO: Determine if we need to inject, not import
import { onMounted, onUnmounted } from 'vue';

/**
 * Uses our event bus to create chimes to be displayed from the app
 * level
 *
 * @returns {{
 *  chime: ref
 *  chimeText: ref
 *  chimeTime: ref
 *  error: (err: any) => void
 *  regularChime: (text: string)
 * }}
 */
export default function useChime() {
  const chime = ref(false);
  const chimeText = ref('');
  const chimeTime = ref(3000);

  onMounted(() => {
    EventBus.$on('chime', regularChime);
    EventBus.$on('error', error);
    EventBus.$on('longChime', longChime);
    EventBus.$on('shortChime', shortChime);
  });

  onUnmounted(() => {
    EventBus.$off('chime', regularChime);
    EventBus.$off('error', error);
    EventBus.$off('longChime', longChime);
    EventBus.$off('shortChime', shortChime);
  });

  function clearChime() {
    chime.value = false;
    chimeText.value = null;
    chimeTime.value = null;
  }

  function error(err) {
    clearChime();
    chimeTime.value = 8000;

    if (err?.response?.data?.errors) {
      const errorCollection = err.response.data.errors;

      if (typeof errorCollection[0] === 'object') {
        chimeText.value = errorCollection.map((err) => err.detail).join(', ');
      } else {
        chimeText.value = errorCollection.join(', ');
      }
    } else if (err?.response?.data?.error) {
      chimeText.value = err.response.data.error;
    } else if (Array.isArray(err)) {
      chimeText.value = err.join('. ');
    } else if (typeof err === 'string') {
      chimeText.value = err;
    } else {
      chimeText.value = 'Unknown error occurred. Please try again or contact support.';
    }

    chime.value = true;
  }

  function longChime(text) {
    chimeTime.value = 8000;
    postChime(text, 8000);
  }

  function postChime(text, time) {
    clearChime();
    chimeTime.value = time;
    chimeText.value = text;
    chime.value = true;
  }

  function regularChime(text) {
    postChime(text, 3000);
  }

  function shortChime(text) {
    postChime(text, 1500);
  }

  return {
    // Refs
    chime,
    chimeText,
    chimeTime,

    // Functions
    error,
    regularChime,
  };
}
